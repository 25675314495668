<template>
  <div class="dropdown relative">
    <div class="flex items-center cursor-pointer" @click="toggleMenu" v-click-outside>
      <div class="uppercase">{{ props.title }}</div>
      <div><ChevronDownIcon class="h-6 w-6"/></div>
    </div>
    <div class="absolute rounded-xl overflow-hidden -translate-x-1/2 left-1/2 whitespace-nowrap top-full mt-4 shadow-md">
      <ul class="bg-white text-bluedot-blue min-w-[95px]" v-if="isOpen">
        <li v-for="(item, index) in props.items" v-bind:key="item.title" v-bind:class="{'border-t border-bluedot-blue/10': index > 0}">
          <a v-if="item.external === true" :href="item.link" class="flex items-center justify-center px-5 h-12 hover:bg-bluedot-blue-hover">{{item.title}}</a>
          <router-link v-else-if="item.external !== true" :to="{path: item.link}" class="flex items-center justify-center px-5 h-12 hover:bg-bluedot-blue-hover">{{item.title}}</router-link>
          <!-- <router-link :to="{ path: item.link }" class="flex items-center justify-center px-5 h-12 hover:bg-bluedot-blue-hover" v-html="item.title"></router-link> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'

const props = defineProps({
  title: String,
  items: {
    type: Array
  }
})

const isOpen = ref(false)

function toggleMenu () {
  isOpen.value = !isOpen.value
}

const vClickOutside = {
  mounted: (el) => {
    el.eventClickOutside = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        if (isOpen.value !== false) {
          isOpen.value = false
        }
      }
    }

    document.body.addEventListener('click', el.eventClickOutside)
  },
  unmounted: (el) => {
    document.body.removeEventListener('click', el.eventClickOutside)
  }
}

</script>
