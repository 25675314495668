<template>
  <!-- <div v-if="$route.path === '/'" class="text-xs flex items-center justify-center h-8 border-b border-b-gray-200">'문체변경 AI' 오웰만을 이용하고 싶은 고객들을 위한 '오웰 웹 버전'은 2월1일 오픈할 예정입니다. 며칠만 기다려주세요.</div> -->
  <header v-if="$route.meta.isHeaderSticky === false" class="blue-header absolute w-full top-0 bg-white z-[9999] px-0 lg:px-12">
    <div class="header bg-white fixed top-0 w-full z-10 shadow-md lg:relative lg:shadow-none">
      <div class="container mx-auto max-w-full">
        <nav>
          <div class="h-20 flex items-center justify-between">
            <div class="logo h-6">
              <router-link to="/">
                <svg width="137" height="22" viewBox="0 0 137 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.1515 21.6534L0 21.6797V0.141968H9.49228C13.8535 0.141968 18.8685 2.34302 19.1849 5.83633C19.3168 7.90605 17.4289 9.57654 15.3248 10.291C17.9984 10.6114 19.3959 12.7074 18.7314 15.1921C17.74 19.0847 15.0453 21.6744 10.1515 21.6534ZM9.18114 4.8435H7.91023C8.006 6.20413 8.006 7.56978 7.91023 8.93041H9.20224C11.4909 8.89889 12.7566 8.20023 12.7618 6.76088C12.7671 5.32153 11.4013 4.81723 9.18114 4.82248V4.8435ZM10.0776 12.5498L7.59382 12.5971C7.43562 13.9839 7.25632 15.3812 7.11394 16.7996L9.46064 16.7523C11.6017 16.7103 12.4982 15.875 12.7724 14.4829C13.0466 13.0909 12.0816 12.4973 10.0776 12.5288V12.5498Z" fill="#3C72FF"/>
                  <path d="M20.5244 21.6324C19.7017 13.7317 26.2831 7.77468 21.0939 0.677734H28.0444C32.6165 6.06742 29.0991 10.6166 27.6964 15.9538C29.9112 15.9538 32.1103 15.945 34.2935 15.9275C33.7277 17.7745 33.6839 19.7412 34.1669 21.6114L20.5244 21.6324Z" fill="#3C72FF"/>
                  <path d="M46.022 21.7532C40.0155 21.79 34.0459 17.9447 37.5844 11.2785C39.1085 7.66441 40.1684 4.20261 36.0129 0.147217H43.3958C47.989 4.55982 46.1591 8.16871 44.3556 12.0035C43.3009 14.1678 44.0075 15.6439 45.8585 15.6491C47.7095 15.6544 49.2019 14.1835 50.3041 12.0508C52.2078 8.237 54.275 4.63862 49.7293 0.15247H56.5848C60.7034 4.44426 58.8682 7.89029 57.0331 11.4361C53.0305 17.9342 52.1234 21.7848 46.022 21.7532Z" fill="#3C72FF"/>
                  <path d="M59.8588 21.6218C54.4745 13.5005 67.0993 8.52582 60.7764 0.745972H73.322C73.8887 1.54273 74.2815 2.44899 74.475 3.40627C74.6685 4.36356 74.6583 5.35061 74.4453 6.30376L68.5126 6.24072C68.3535 7.10016 68.0878 7.93657 67.7216 8.73069L72.5995 8.79373C71.856 10.4327 70.8593 12.0244 70.1896 13.6581L65.301 13.5951C64.9449 14.3986 64.6933 15.2442 64.5522 16.1113L71.0069 16.1743C70.8382 17.8816 71.3023 19.6782 72.9792 21.6586L59.8588 21.6218Z" fill="#3C72FF"/>
                  <path d="M84.1702 21.6428H76.1334C69.0089 13.574 82.4194 8.55204 76.5816 0.168091H84.6184C92.1384 0.168091 98.8041 4.78557 94.9808 11.1996C91.4265 17.6714 90.8306 21.5797 84.1702 21.6428ZM85.3198 6.36675H84.2967C83.4213 9.6552 80.9164 12.5759 80.6053 15.7541H81.66C84.7028 15.7803 86.5749 13.905 87.9882 11.1261C89.4806 8.21059 88.1305 6.38251 85.3198 6.36675Z" fill="#3C72FF"/>
                  <path d="M109.494 22C102.596 21.9685 92.5764 17.1409 96.0991 11.3309C99.6218 5.52101 103.06 0.0315186 109.552 0C116.671 0.0840497 121.053 5.17956 119.36 11.2416C117.473 17.3352 115.669 21.9317 109.494 22ZM109.161 6.11987C106.688 6.11987 104.12 8.42598 102.944 11.3047C101.768 14.1834 103.935 16.3687 106.683 16.3897C109.43 16.4107 111.134 14.2884 112.014 11.3467C112.895 8.40497 111.746 6.10411 109.161 6.11987Z" fill="#3C72FF"/>
                  <path d="M132.122 6.03065C131.748 11.31 131.173 16.4213 132.296 21.6797H125.341C122.704 16.4265 124.17 11.4939 125.035 6.18825L120.763 6.25654C121.172 4.44689 121.363 2.59534 121.333 0.74078L136.584 0.667236V5.92034L132.122 6.03065Z" fill="#3C72FF"/>
                </svg>
              </router-link>
            </div>
            <!-- Mobile -->
            <div class="lg:hidden flex items-center">
              <button class="block" @click="drawerOpen"><MenuIcon class="w-9 h-9" /></button>
            </div>

            <TheDrawer @drawer="drawerClose" v-if="isDrawerOpen" :navigation="navigation" />

            <div class="hidden lg:flex text-sm font-bold gap-x-6">
              <div class="flex mr-4">
                <ul class="flex items-center gap-x-6">
                  <li v-for="menu in navigation" v-bind:key="menu">
                    <a class="uppercase" :href="menu.link" v-if="menu.external === true">{{ menu.title }}</a>
                    <router-link v-else-if="menu.type == 'single'" :to="menu.link" class="uppercase">{{ menu.title }}</router-link>
                    <DropdownMenu v-else-if="menu.type == 'nested'" :title="menu.title" :items="menu.submenu" />
                  </li>
                </ul>
              </div>

              <div class="flex items-center">
                <TheSearch />
              </div>

              <div class="flex items-center">
                <router-link to="/signin" class="text-gray-o9">로그인</router-link>
              </div>

              <div class="flex items-center">
                <router-link to="/apply" class="rounded-full border border-bluedot-blue h-[37px] flex items-center justify-center px-7 bg-bluedot-blue text-white transition hover:bg-white hover:text-bluedot-blue">시작하기</router-link>
              </div>
            </div>

          </div>
        </nav>
      </div>
    </div>
  </header>
  <header v-else class="blue-header sticky w-full top-0 bg-white z-[9999] px-0 lg:px-12">
    <div class="header bg-white fixed top-0 w-full z-10 shadow-md lg:relative lg:shadow-none">
      <div v-if="$route.path === '/'" class="lg:hidden text-xs flex items-center justify-center border-b border-b-gray-200 px-5 py-2">'문체변경 AI' 오웰만을 이용하고 싶은 고객들을 위한 '오웰 웹 버전'은 2월1일 오픈할 예정입니다. 며칠만 기다려주세요.</div>
      <div class="container mx-auto max-w-full">
        <nav>
          <div class="h-20 flex items-center justify-between">
            <div class="logo h-6">
              <router-link to="/">
                <svg width="137" height="22" viewBox="0 0 137 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.1515 21.6534L0 21.6797V0.141968H9.49228C13.8535 0.141968 18.8685 2.34302 19.1849 5.83633C19.3168 7.90605 17.4289 9.57654 15.3248 10.291C17.9984 10.6114 19.3959 12.7074 18.7314 15.1921C17.74 19.0847 15.0453 21.6744 10.1515 21.6534ZM9.18114 4.8435H7.91023C8.006 6.20413 8.006 7.56978 7.91023 8.93041H9.20224C11.4909 8.89889 12.7566 8.20023 12.7618 6.76088C12.7671 5.32153 11.4013 4.81723 9.18114 4.82248V4.8435ZM10.0776 12.5498L7.59382 12.5971C7.43562 13.9839 7.25632 15.3812 7.11394 16.7996L9.46064 16.7523C11.6017 16.7103 12.4982 15.875 12.7724 14.4829C13.0466 13.0909 12.0816 12.4973 10.0776 12.5288V12.5498Z" fill="#3C72FF"/>
                  <path d="M20.5244 21.6324C19.7017 13.7317 26.2831 7.77468 21.0939 0.677734H28.0444C32.6165 6.06742 29.0991 10.6166 27.6964 15.9538C29.9112 15.9538 32.1103 15.945 34.2935 15.9275C33.7277 17.7745 33.6839 19.7412 34.1669 21.6114L20.5244 21.6324Z" fill="#3C72FF"/>
                  <path d="M46.022 21.7532C40.0155 21.79 34.0459 17.9447 37.5844 11.2785C39.1085 7.66441 40.1684 4.20261 36.0129 0.147217H43.3958C47.989 4.55982 46.1591 8.16871 44.3556 12.0035C43.3009 14.1678 44.0075 15.6439 45.8585 15.6491C47.7095 15.6544 49.2019 14.1835 50.3041 12.0508C52.2078 8.237 54.275 4.63862 49.7293 0.15247H56.5848C60.7034 4.44426 58.8682 7.89029 57.0331 11.4361C53.0305 17.9342 52.1234 21.7848 46.022 21.7532Z" fill="#3C72FF"/>
                  <path d="M59.8588 21.6218C54.4745 13.5005 67.0993 8.52582 60.7764 0.745972H73.322C73.8887 1.54273 74.2815 2.44899 74.475 3.40627C74.6685 4.36356 74.6583 5.35061 74.4453 6.30376L68.5126 6.24072C68.3535 7.10016 68.0878 7.93657 67.7216 8.73069L72.5995 8.79373C71.856 10.4327 70.8593 12.0244 70.1896 13.6581L65.301 13.5951C64.9449 14.3986 64.6933 15.2442 64.5522 16.1113L71.0069 16.1743C70.8382 17.8816 71.3023 19.6782 72.9792 21.6586L59.8588 21.6218Z" fill="#3C72FF"/>
                  <path d="M84.1702 21.6428H76.1334C69.0089 13.574 82.4194 8.55204 76.5816 0.168091H84.6184C92.1384 0.168091 98.8041 4.78557 94.9808 11.1996C91.4265 17.6714 90.8306 21.5797 84.1702 21.6428ZM85.3198 6.36675H84.2967C83.4213 9.6552 80.9164 12.5759 80.6053 15.7541H81.66C84.7028 15.7803 86.5749 13.905 87.9882 11.1261C89.4806 8.21059 88.1305 6.38251 85.3198 6.36675Z" fill="#3C72FF"/>
                  <path d="M109.494 22C102.596 21.9685 92.5764 17.1409 96.0991 11.3309C99.6218 5.52101 103.06 0.0315186 109.552 0C116.671 0.0840497 121.053 5.17956 119.36 11.2416C117.473 17.3352 115.669 21.9317 109.494 22ZM109.161 6.11987C106.688 6.11987 104.12 8.42598 102.944 11.3047C101.768 14.1834 103.935 16.3687 106.683 16.3897C109.43 16.4107 111.134 14.2884 112.014 11.3467C112.895 8.40497 111.746 6.10411 109.161 6.11987Z" fill="#3C72FF"/>
                  <path d="M132.122 6.03065C131.748 11.31 131.173 16.4213 132.296 21.6797H125.341C122.704 16.4265 124.17 11.4939 125.035 6.18825L120.763 6.25654C121.172 4.44689 121.363 2.59534 121.333 0.74078L136.584 0.667236V5.92034L132.122 6.03065Z" fill="#3C72FF"/>
                </svg>
              </router-link>
            </div>
            <!-- Mobile -->
            <div class="lg:hidden flex items-center">
              <button class="block" @click="drawerOpen"><MenuIcon class="w-9 h-9" /></button>
            </div>

            <TheDrawer @drawer="drawerClose" v-if="isDrawerOpen" :navigation="navigation" />

            <div class="hidden lg:flex text-sm font-bold gap-x-6">
              <div class="flex mr-4">
                <ul class="flex items-center gap-x-6">
                  <li v-for="menu in navigation" v-bind:key="menu">
                    <a class="uppercase" :href="menu.link" v-if="menu.external === true">{{ menu.title }}</a>
                    <router-link v-else-if="menu.type == 'single'" :to="menu.link" class="uppercase">{{ menu.title }}</router-link>
                    <DropdownMenu v-else-if="menu.type == 'nested'" :title="menu.title" :items="menu.submenu" />
                  </li>
                </ul>
              </div>

              <div class="flex items-center">
                <TheSearch />
              </div>

              <div class="flex items-center">
                <router-link to="/signin" class="text-gray-o9">로그인</router-link>
              </div>

              <div class="flex items-center">
                <router-link to="/apply" class="rounded-full border border-bluedot-blue h-[37px] flex items-center justify-center px-7 bg-bluedot-blue text-white transition hover:bg-white hover:text-bluedot-blue">시작하기</router-link>
              </div>
            </div>

          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref } from 'vue'
import { MenuIcon } from '@heroicons/vue/outline'
import DropdownMenu from '@/components/DropdownMenu.vue'
import TheSearch from './TheSearch.vue'
import TheDrawer from './TheDrawer.vue'
import MainMenu from '@/data/MainMenu'

const isDrawerOpen = ref(false)

function drawerOpen () {
  isDrawerOpen.value = true
  document.body.classList.add('view-mobile-menu')
}

function drawerClose () {
  isDrawerOpen.value = false
  document.body.classList.remove('view-mobile-menu')
}

const navigation = ref(MainMenu)
</script>
