<template>
  <AlterHeader v-if="route.meta.header === 'alter'" />
  <TheHeader v-else />

  <main>
    <div class="main mt-20 md:mt-0">
      <router-view v-slot="{ Component, route }">
        <!-- Use any custom transition and fallback to `fade` -->
        <transition :name="route.meta.transition">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </main>
</template>

<style lang="stylus">
body.view-mobile-menu
  height: 100vh
  overflow: hidden;

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s ease;

.fade-enter-from, .fade-leave-to
  opacity: 0;
</style>

<script setup>
import { useRoute } from 'vue-router'
import TheHeader from '@/components/TheHeader.vue'
import AlterHeader from '@/components/AlterHeader.vue'
import AOS from 'aos'
import { onMounted } from 'vue'
import 'aos/dist/aos.css'

onMounted(() => AOS.init({ once: true }))

const route = useRoute()
</script>
