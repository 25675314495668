<template>
  <div class="search">
    <div class="trigger" @click="toggleSearch" v-click-outside>
      <button><SearchIcon class="h-5 w-5"/></button>
    </div>
    <div class="form" v-if="isOpen">
      <form action="/posts" method="get" @submit.prevent="submitForm">
        <input ref="searchInput" name="q" v-model="q" class="search-text" type="text" placeholder="검색" />
        <button class="ml-2" type="submit"><SearchIcon class="h-5 w-5 text-bluedot-blue"/></button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { SearchIcon } from '@heroicons/vue/solid'

const isOpen = ref(false)
const searchInput = ref(null)

function toggleSearch () {
  isOpen.value = !isOpen.value
  nextTick(function () {
    if (isOpen.value) {
      searchInput.value.focus()
    }
  })
}

const vClickOutside = {
  mounted: (el) => {
    el.eventClickOutside = function (event) {
      if (!(el === event.target ||
        el.contains(event.target) ||
        event.target.closest('.search-text') ||
        event.target.closest('.form'))) {
        if (isOpen.value !== false) {
          isOpen.value = false
        }
      }
    }

    document.body.addEventListener('click', el.eventClickOutside)
  },
  unmounted: (el) => {
    document.body.removeEventListener('click', el.eventClickOutside)
  }
}

const q = ref('')
const router = useRouter()

const submitForm = () => {
  // this.$router.push('/posts?q' + q.value)
  isOpen.value = false
  router.push({
    name: 'posts',
    query: {
      q: q.value
    }
  })
}
</script>

<style lang="stylus">
.header
  .search
    position: relative
    .trigger
      display: flex
    .form
      align-items: stretch
      background: #fff
      border: 0.5px solid #e6e6e6
      border-radius: 0.5rem
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1)
      display: flex
      height: 45px
      margin-top: 1rem
      overflow: hidden
      position: absolute
      right: -1rem
      top: 100%
      width: 270px
      @media (max-width: 1039px)
        right: 0

      form
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        padding: 0 1rem
    .search-text
      flex-grow: 1
      padding: 0.5rem 0.25rem
      color: black
      line-height: 1.4
      &:focus
        outline: 2px solid transparent
        outline-offset: 2px

</style>
