<template>
  <header>
    <div class="header bg-bluedot-blue text-white fixed top-0 w-full z-50 shadow-md md:relative md:shadow-none">
      <div class="container mx-auto max-w-full">
        <div class="h-15 flex items-center">

          <div class="flex items-end gap-x-2">
            <div class="logo h-4 flex items-center gap-x-1">
              <router-link to="/" class="block h-full"><img class="h-full" src="../assets/images/bluedot-logo.svg" alt="블루닷 로고"></router-link>
            </div>
          </div>

        </div>
      </div>
    </div>
  </header>
</template>
